const INITIAL_STATE = {
  caculate: null,
  updLecture: null,
  insMemo: null,
  insHis: null,
  getTestPar: null,
  insTestPar: null,
  updTestPar: null,
  updParticipant: null,
  getLecture: null,
  getQuestion: null,
  getTest: null,
  viewLecture: null,
  getNextRow: null,
  createdInUser: null,
  loggedInUser: null,
  getTextbook: null,
  getTextbooks: null,
  getSupplementary: null,
  getSupplementaries: null
}

export function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CACULATE':
      return {
        ...state,
        caculate: action.data,
      }
    case 'GET_TEXTBOOK':
      return {
        ...state,
        getTextbook: action.data,
      }
    case 'GET_TEXTBOOKs':
      return {
        ...state,
        getTextbooks: action.data,
      }
    case 'GET_SUPPLEMENTARY':
      return {
        ...state,
        getSupplementary: action.data,
      }
    case 'GET_SUPPLEMENTARIES':
      return {
        ...state,
        getSupplementaries: action.data,
      }
    case 'INS_HIS':
      return {
        ...state,
        insHis: action.data,
      }
    case 'GET_TESTPAR':
      return {
        ...state,
        getTestPar: action.data,
      }
    case 'INS_TESTPAR':
      return {
        ...state,
        insTestPar: action.data,
      }
    case 'UPD_TESTPAR':
      return {
        ...state,
        updTestPar: action.data,
      }
    case 'UPD_PARTICIPANT':
      return {
        ...state,
        updParticipant: action.data,
      }
    case 'INS_MEMO':
      return {
        ...state,
        insMemo: action.data,
      }
    case 'UPD_LECTURE':
      return {
        ...state,
        updLecture: action.data,
      }
    case 'VIEW_LECTURE':
      return {
        ...state,
        viewLecture: action.data,
      }
    case 'GET_NEXTROW':
      return {
        ...state,
        getNextRow: action.data,
      }
    case 'GET_QUESTION':
      return {
        ...state,
        getQuestion: action.data,
      }
    case 'GET_TEST':
      return {
        ...state,
        getTest: action.data,
      }
    case 'GET_LECTURE':
      return {
        ...state,
        getLecture: action.data,
      }
    case 'LOGIN_USER':
      return {
        ...state,
        loggedInUser: action.data,
      }
    case 'SIGNUP':
      return {
        ...state,
        createdInUser: action.data,
      }
    case 'LOGOUT':
      return {
        ...state,
        loggedInUser: null,
      }
    default:
      return state
  }
}
