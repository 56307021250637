import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
// import { useMediaQuery } from "react-responsive";
import { LogoutModal } from "../cmps/modal/LogoutModal";
import { AlertModal } from "../cmps/modal/AlertModal";

export default function Sidebar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  // const isDesktop = useMediaQuery({ minWidth: 992 });
  const [isLogoutModal, setIsLogoutModal] = useState(false);
  const [isAlertModal, setIsAlertModal] = useState(false);

  const toggleLogoutModal = () => {
    if (location.pathname === '/home/lecturevideo') {
      history.push('/home/table')
    }
    setIsLogoutModal((prev) => !prev);
  };
  const toggleAlertModal = () => {
    if (location.pathname === '/home/lecturevideo') {
      history.push('/home/table')
    }
    setIsAlertModal((prev) => !prev);
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        if (isLogoutModal === true) {
          setIsLogoutModal(false);
        }
        if (isAlertModal === true) {
          setIsAlertModal(false);
        }
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isAlertModal, isLogoutModal]);

  return (
    <div className="sidebar">
      <div className="sidebar-menu">
        {isLogoutModal && <LogoutModal toggleLogoutModal={toggleLogoutModal} />}
        {isAlertModal && <AlertModal toggleAlertModal={toggleAlertModal} />}

        <ul className="sidebar-list">
          <Link className="link" to={"/home/table"}>
            <li className={location.pathname === '/home/table' ? 'sidebar-icon active' : 'sidebar-icon'}>
              講義 <p className="mini">(こうぎ)</p>
            </li>
          </Link>

          <Link className="link" to={"/home/textbook"}>
            <li className={location.pathname === '/home/textbook' ? 'sidebar-icon active' : 'sidebar-icon'}>
              教科書 <p className="mini">(きょうかしょ)</p>
            </li>
          </Link>

          <Link className="link" to={"/home/supplementary"}>
            <li className={location.pathname === '/home/supplementary' ? 'sidebar-icon active' : 'sidebar-icon'}>
              補助教材 <p className="mini">(ほじょきょうざい)</p>
            </li>
          </Link>

          {/* <Link className="link" to={"/home/tests"}>
            <li className={location.pathname === '/home/tests' ? 'sidebar-icon active' : 'sidebar-icon'}>
              テスト
            </li>
          </Link> */}
          <li className={'sidebar-icon'} onClick={toggleAlertModal}>
            テスト
          </li>
          <Link className="link" to={"/home/setting"}>
            <li className={location.pathname === '/home/setting' ? 'sidebar-icon active' : 'sidebar-icon'}>
              設定 <p className="mini">(せってい)</p>
            </li>
          </Link>
          <li className={'sidebar-icon'} onClick={toggleLogoutModal}>
            ログアウト
          </li>
        </ul>
      </div>
    </div>
  );
}
