import { useEffect } from 'react'
import cross from "../../assets/imgs/cross.png";

export function TearmModal({ toggleShowTearmModal }) {


  useEffect(() => { }, [])

  return (
    <section className="tearms">
      <div className="bg" onClick={toggleShowTearmModal}></div>
      <div className="container">
        <div className="x-title">
          <span onClick={toggleShowTearmModal}>
            <img src={cross} alt="Toyohozai" className="title-icon" />
          </span>
        </div>
        <div className="title">
          <h1>利用規約<span className='minf'>（りようきやく）</span></h1>
        </div>
        <div className="txt"><p>利用規約テキスト</p></div>
        <div className="form-container">

          <div className='form-title'>第１条（だい1じょう）</div>
          <div className='form-txt'>本規約（ほんきやく）は（一社（いっしゃ））全国農業会議所（ぜんこくのうぎょうかいぎしょ）（以下（いか）、会議所（かいぎしょ））が実施（じっし）するeラーニングの受講申込者（じゅこうもうしこみしゃ）、受講者（じゅこうしゃ）に適用（てきよう）します。</div>
          <div className='form-txt'>
            <p>eラーニングの受講申込（じゅこうもうしこみ）を行（おこな）う時点（じてん）で、本規約（ほんきやく）の内容（ないよう）を承諾（しょうだく）しているものとみなします。</p>
          </div>

          <div className='form-title'>第２条（だい2じょう）</div>
          <div className='form-txt'>本規約（ほんきやく）は必要（ひつよう）に応（おう）じて変更（へんこう）することがあります。変更後（へんこうご）のeラーニングの利用（りよう）には、変更後（へんこうご）の規約（きやく）を適用（てきよう）します。</div>
          <div className='form-txt'>
            <p>変更後（へんこうご）の規約（きやく）は、会議所（かいぎしょ）が適当（てきとう）と判断（はんだん）する方法（ほうほう）で通知（つうち）します。</p>
          </div>

          <div className='form-title'>第３条（だい3じょう）</div>
          <div className='form-txt'>ログイン情報（じょうほう）(ID、パスワード)は、受講（じゅこう）申（もう）し込（こ）みを承諾（しょうだく）する場合（ばあい）、会議所（かいぎしょ）が適当（てきとう）と判断（はんだん）する方法（ほうほう）で通知（つうち）します。</div>
          <div className='form-txt'></div>


          <div className='form-title'>第４条（だい4じょう）</div>
          <div className='form-txt'>受講者（じゅこうしゃ）はeラーニングの利用（りよう）にあたり、会議所（かいぎしょ）が定（さだ）める利用条件（りようじょうけん）、利用方法（りようほうほう）を守（まも）らなければなりません。</div>
          <div className='form-txt'></div>

          <div className='form-title'>第５条（だい5じょう）</div>
          <div className='form-txt'>受講者（じゅこうしゃ）はeラーニングの利用（りよう）にあたり、以下（いか）の行為（こうい）または会議所（かいぎしょ）が不適切（ふてきせつ）と認（みと）める行為（こうい）をしてはなりません。</div>
          <ol class="pg-rules-listnumber">
            <li>ID、パスワードの不正（ふせい）な利用（りよう）</li>
            <li>知的財産権（ちてきざいさんけん）を侵害（しんがい）する行為（こうい）、侵害（しんがい）する恐（おそ）れのある行為（こうい）</li>
            <li>eラーニングの利用（りよう）を通（つう）じて入手（にゅうしゅ）したデータ、情報等（じょうほうなど）に関（かん）し、著作権法（ちょさくけんほう）で認（みと）められた私的利用（してきりよう）の範囲（はんい）を超（こ）え、複製（ふくせい）、販売（はんばい）、出版等（しゅっぱんなど）を行（おこな）う行為（こうい）</li>
            <li>その他（た）、法令（ほうれい）、本規約（ほんきやく）に違反（いはん）する行為（こうい）、eラーニングの運営（うんえい）を妨（さまた）げる行為（こうい）、会議所（かいぎしょ）、第三者（だいさんしゃ）に不利益（ふりえき）を与（あた）える行為（こうい）、会議所（かいぎしょ）、第三者（だいさんしゃ）の権利（けんり）を侵害（しんがい）する行為等（こういなど）</li>
            <li>会議所（かいぎしょ）は前項（ぜんこう）で定（さだ）める行為（こうい）を発見（はっけん）した場合（ばあい）、当該受講者（とうがいじゅこうしゃ）のeラーニングの利用（りよう）を停止（ていし）することができます。</li>
          </ol>

          <div className='form-title'>第６条（だい6じょう）</div>
          <div className='form-txt'>
            <p>受講者（じゅこうしゃ）はeラーニングの利用（りよう）にあたり、会議所（かいぎしょ）、第三者（だいさんしゃ）に損害（そんがい）を与（あた）えた場合（ばあい）、自己（じこ）の責任（せきにん）と費用（ひよう）により損害（そんがい）を賠償（ばいしょう）するものとします。</p>
          </div>

          <div className='form-title'>第７条（だい7じょう）</div>
          <div className='form-txt'>
            <p>eラーニングは技術（ぎじゅつ）・保守等（ほしゅなど）の都合（つごう）で一時的（いちじてき）な中断（ちゅうだん）が必要（ひつよう）となった場合（ばあい）、また、インターネットの利用（りよう）が出来（でき）なくなった場合（ばあい）、災害（さいがい）、紛争等（ふんそうなど）により実施（じっし）を停止（ていし）、中断（ちゅうだん）する場合（ばあい）があります。</p>
          </div>

          <div className='form-title'>第８条（だい8じょう）</div>
          <div className='form-txt'>
            <p>会議所（かいぎしょ）はeラーニングの利用（りよう）により発生（はっせい）した損害（そんがい）、eラーニング停止（ていし）、中断（ちゅうだん）により生（しょう）じた損害（そんがい）について、損害賠償責任等（そんがいばいしょうせきにんなど）の一切（いっさい）の責任（せきにん）を負（お）いません。</p>
          </div>

          <div className='form-title'>第９条（だい9じょう）</div>
          <div className='form-txt'>
            <p>本規約（ほんきやく）、eラーニングの実施（じっし）に関（かん）する準拠法（じゅんきょほう）は日本法（にほんほう）とします。</p>
            <p>附 則（ふそく）</p>
            <p>本規約（ほんきやく）は2024年（ねん）●月（がつ）●日（にち）から実施（じっし）します。</p>
          </div>

        </div >

      </div >
    </section >
  )
}
