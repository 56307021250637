import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import Minibar from "./Minibar";
import logom1 from "../assets/imgs/logom1.png";

export default function Topbar({ showSidebar }) {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return (
    <div className="topbar">
      <div className="topbar-container">
        <div className="leftTopbar">
          {!isDesktop && (
            <Minibar />
          )}
          <Link to={"/home/main"}>
            <img src={logom1} alt="serviceLogo" className="logo" />
          </Link>
        </div>
        <div className="rightTopbar">
          <div className="rightIconContainer">
            {/* <NotificationMenu/> */}
          </div>
          <div className="rightIconContainer">
            {/* <SettingsMenu/> */}
          </div>
        </div>
      </div>
    </div>
  );
}
