const nationData1 = [
    "インド",
    "インドネシア",
    "ウズベキスタン",
    "カンボジア",
    "キルギス",
    "スリランカ",
    "タイ",
    "中国",
    "日本",
    "ネパール",
    "パキスタン",
    "バングラデシュ",
    "フィリピン",
    "ベトナム",
    "マレーシア",
    "ミャンマー",
    "モンゴル",
    "ラオス",
    "その他（ほか）"
]

export { nationData1 };