import { lazy, Suspense, useEffect, useState, useRef } from "react";
import { Switch, useHistory } from 'react-router-dom'
import { Offline } from "react-detect-offline";
import { userService } from '../services/user/userService'
import { useMediaQuery } from "react-responsive";
import { CSSTransition } from "react-transition-group";

import PrivateRoute from "../cmps/PrivateRoute";
import Topbar from "../components/Topbar";
import Sidebar from "../components/Sidebar";

const Setting = lazy(() => import("./Setting"));
const Lecture = lazy(() => import("./Lecture"));
const Tests = lazy(() => import("./Tests"));
const Textbook = lazy(() => import("./Textbook"));
const Supplementary = lazy(() => import("./Supplementary"));

const TestCard = lazy(() => import("../figma/TestDetail/TestCard"));
const LectureVideo = lazy(() => import("./../figma/LectureVideo/LectureVideo"));
const SurveyComponent = lazy(() => import("../figma/SurveyComponent/SurveyComponent"));
const LectureList = lazy(() => import("./../figma/LectureList/LectureList"));

const NotFound = lazy(() => import("./Notfound"));

export function Home() {
  const history = useHistory();
  const nodeRef = useRef(null)
  const isDesktop = useMediaQuery({ minWidth: 992 });

  const isAuthenticated = userService.getLoginUser();
  const [hamburgerSidebar, setHamburgerSidebar] = useState(false);

  const showSidebar = () => {
    setHamburgerSidebar((prev) => !prev);
  };

  useEffect(() => {
    if (isAuthenticated) {
      history.replace("/");
      setTimeout(() => {
        history.replace("/home/main");
      }, 10);
    }
  }, [history])

  return (
    <div className="home-page">
      <div className="main">
        <Topbar showSidebar={showSidebar} />
        <div className="main-container">
          {isDesktop && !hamburgerSidebar && <Sidebar />}
          <CSSTransition nodeRef={nodeRef} in={hamburgerSidebar} timeout={200} classNames="sidebar" unmountOnExit>
            <Sidebar />
          </CSSTransition>
          <Suspense>
            <Switch>
              <PrivateRoute path="/home/main" component={Lecture} />

              <PrivateRoute path="/home/table" component={Lecture} />
              <PrivateRoute path="/home/lecturevideo" component={LectureVideo} />
              <PrivateRoute path="/home/tests" component={Tests} />
              <PrivateRoute path="/home/testcard" component={TestCard} />
              <PrivateRoute path="/home/survey" component={SurveyComponent} />
              <PrivateRoute path="/home/textbook" component={Textbook} />
              <PrivateRoute path="/home/supplementary" component={Supplementary} />
              <PrivateRoute path="/home/setting" component={Setting} />
              <PrivateRoute path="/home/yyy" component={LectureList} />
              <PrivateRoute path="*/*" component={NotFound} />
            </Switch>
          </Suspense>
          <Offline>
            <div className="detect">
              <span>おっと、オフラインです</span>
            </div>
          </Offline>
        </div>
      </div>
    </div>
  );
}
