import { userService } from '../../services/user/userService'

export function getParticipant() {
  return async (dispatch) => {
    try {
      const res = await userService.getParticipant();
      dispatch({ type: 'GET_PARTICIPANT', res })
      return res;
    } catch (err) {
      console.log('cannot getParticipant:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}


export function updParticipant(participant_id,body) {
  return async (dispatch) => {
    try {
      const res = await userService.updParticipant(participant_id,body);
      dispatch({ type: 'UPD_PARTICIPANT', res })
      return res;
    } catch (err) {
      console.log('cannot updParticipant:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function caculateAct(body) {
  return async (dispatch) => {
    try {
      const res = await userService.caculate(body);
      dispatch({ type: 'CACULATE', res })
      return res;
    } catch (err) {
      console.log('cannot caculate:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function getStorageAct(key) {
  return async (dispatch) => {
    try {
      const res = await userService.getStorage(key);
      dispatch({ type: 'GET_STORAGE', res })
      return res;
    } catch (err) {
      console.log('cannot get storage:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function clearStorageAct(key) {
  return async () => {
    try {
      return await userService.clearStorage(key);
    } catch (err) {
      console.log('cannot get storage:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function getTestParAct(test_id) {
  return async (dispatch) => {
    try {
      const res = await userService.getTestPar(test_id);
      dispatch({ type: 'GET_TESTPAR', res })
      return res;
    } catch (err) {
      console.log('cannot get TestPar:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function insTestParAct(test_id,body) {
  return async (dispatch) => {
    try {
      const res = await userService.insTestPar(test_id,body);
      dispatch({ type: 'INS_TESTPAR', res })
      return res;
    } catch (err) {
      console.log('cannot ins TestPar:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function updTestParAct(test_id,body) {
  return async (dispatch) => {
    try {
      const res = await userService.updTestPar(test_id,body);
      dispatch({ type: 'UPD_TESTPAR', res})
      return res;
    } catch (err) {
      console.log('cannot upd TestPar:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function insMemoAct(lecture_id,body) {
  return async (dispatch) => {
    try {
      const res = await userService.insMemo(lecture_id,body);
      dispatch({ type: 'INS_MEMO', res })
      return res;
    } catch (err) {
      console.log('cannot insMemo:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function insHisAct(body) {
  return async (dispatch) => {
    try {
      const res = await userService.insHis(body);
      dispatch({ type: 'INS_HIS', res })
      return res;
    } catch (err) {
      console.log('cannot insHis:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function updLectureAct(lecture_id,body) {
  return async (dispatch) => {
    try {
      const res = await userService.updLecture(lecture_id,body);
      dispatch({ type: 'UPD_LECTURE', res })
      return res;
    } catch (err) {
      console.log('cannot upd lectures:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function viewLectureAct(lecture_id) {
  return async (dispatch) => {
    try {
      const res = await userService.viewLecture(lecture_id)
      dispatch({ type: 'VIEW_LECTURE', res })
      return res;
    } catch (err) {
      console.log('cannot get lectures:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function getNextRowAct(lecture_id) {
  return async (dispatch) => {
    try {
      const res = await userService.getNextRow(lecture_id)
      dispatch({ type: 'GET_NEXTROW', res })
      return res;
    } catch (err) {
      console.log('cannot get next row:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function getQuestionAct(test_id) {
  return async (dispatch) => {
    try {
      const res = await userService.getQuestion(test_id)
      dispatch({ type: 'GET_QUESTION', res })
      return res;
    } catch (err) {
      console.log('cannot get Question:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function getTestAct() {
  return async (dispatch) => {
    try {
      const res = await userService.getTest()
      dispatch({ type: 'GET_TEST', res })
      return res;
    } catch (err) {
      console.log('cannot get Test:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function getLectureAct() {
  return async (dispatch) => {
    try {
      const res = await userService.getLecture()
      dispatch({ type: 'GET_LECTURE', res })
      return res;
    } catch (err) {
      console.log('cannot get lectures:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function login(userCred) {
  return async (dispatch) => {
    try {
      const res = await userService.login(userCred)
      dispatch({ type: 'LOGIN_USER', res })
      return res
    } catch (err) {
      console.log("can't do login:", err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function getLoginUser() {
  return async (dispatch) => {
    try {
      const res = await userService.getLoginUser()
      dispatch({ type: 'LOGIN_USER', res })
      return res
    } catch (err) {
      console.log('cannot getLoginUser:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function getCreatedinUser(email) {
  return async (dispatch) => {
    try {
      const res = await userService.getCreatedinUser(email)
      dispatch({ type: 'CREATED_IN_USER', res })
      return res
    } catch (err) {
      console.log('cannot getCreatedinUser:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function signup(userCred) {
  return async (dispatch) => {
    try {
      const res = await userService.signup(userCred)
      dispatch({ type: 'SIGNUP', res })
      return res
    } catch (err) {
      console.log('cannot signup:', err)
      dispatch({ type: 'SET_NOTI', noti: '登録中にエラーが発生しました' })
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function logout() {
  return async (dispatch) => {
    try {
      return await userService.logout()
    } catch (err) {
      console.log('cannot logout:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function sendToEmail(emailCred, type) {
  return async (dispatch) => {
    try {
      return await userService.sendToEmail(emailCred, type)
    } catch (err) {
      console.log("can't do sent email:", err)
      dispatch({ type: 'SET_NOTI', noti: 'メール送信に失敗しました' })
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function getTextbooksAct() {
  return async (dispatch) => {
    try {
      const res = await userService.getTextbooks();
      dispatch({ type: 'GET_TEXTBOOKS', res })
      return res;
    } catch (err) {
      console.log('cannot getTextbooks:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function getTextbookAct(id) {
  return async (dispatch) => {
    try {
      const res = await userService.getTextbook(id);
      dispatch({ type: 'GET_TEXTBOOK', res })
      return res;
    } catch (err) {
      console.log('cannot getTextbook:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function getSupplementariesAct() {
  return async (dispatch) => {
    try {
      const res = await userService.getSupplementaries();
      dispatch({ type: 'GET_SUPPLEMENTARIES', res })
      return res;
    } catch (err) {
      console.log('cannot getSupplementaries:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}

export function getSupplementaryAct(id) {
  return async (dispatch) => {
    try {
      const res = await userService.getSupplementary(id);
      dispatch({ type: 'GET_SUPPLEMENTARY', res })
      return res;
    } catch (err) {
      console.log('cannot getSupplementary:', err)
      return {
        status: 500,
        message: err
      };
    }
  }
}