import React, { useState } from 'react';
import { MenuModal } from '../cmps/modal/MenuModal';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';

const Minibar = () => {
    const [isShowMenu, setIsShowMenu] = useState(false);
    const toggleShowMenu = () => {
        setIsShowMenu((prev) => !prev);
    };
    return (
        <div>
            <FormatAlignLeftIcon onClick={toggleShowMenu} />
            {isShowMenu && <MenuModal toggleShowMenu={toggleShowMenu} />}
        </div>
    );
};

export default Minibar;
