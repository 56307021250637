import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as Menuicon } from "../../assets/imgs/menuicon.svg";
import { LogoutModal } from "../../cmps/modal/LogoutModal";
import { AlertModal } from "../../cmps/modal/AlertModal";
import { getParticipant } from "../../store/actions/userActions";

import cross from "../../assets/imgs/cross.png";
import styles from '../../figma/MenuComponent/MenuComponent.module.css';

const menuItems = [
  { label: "講義", minf: "（こうぎ）", link: "/home/table" },
  { label: "教科書", minf: "（きょうかしょ）", link: "/home/textbook" },
  { label: "補助教材", minf: "（ほじょきょうざい）", link: "/home/supplementary" },
  { label: "テスト", minf: "", link: "/home/tests" },
  { label: "設定", minf: "（せってい）", link: "/home/setting" }
];

export function MenuModal({ toggleShowMenu }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLogoutModal, setIsLogoutModal] = useState(false);
  const [isAlertModal, setIsAlertModal] = useState(false);

  const [loggedUser, setLoggedUser] = useState(null);

  const handleClose = (link) => {
    toggleShowMenu()
    history.push(link);
  };

  const toggleLogoutModal = () => {
    setIsLogoutModal((prev) => !prev);
  };

  const toggleAlertModal = () => {
    setIsAlertModal((prev) => !prev);
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        if (isLogoutModal === true) {
          setIsLogoutModal(false);
        }
        if (isAlertModal === true) {
          setIsAlertModal(false);
        }
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isAlertModal, isLogoutModal]);

  useEffect(() => {
    dispatch(getParticipant()).then((user) => {
      if (user !== null && user.status === 200) {
        let participant = user.data.participant
        setLoggedUser(participant[0]);
      }
    });
  }, [dispatch]);

  return (
    <section className={styles.mtearms}>
      <div className={styles.mbg} onClick={toggleShowMenu}></div>
      <div className={styles.mcontainer}>
        <div className={styles.mtitle}>
          <span onClick={toggleShowMenu}>
            <img src={cross} className={styles.micon} />
          </span>
          <p>{loggedUser && loggedUser.email} <span className={styles.minf}>（さま）</span></p>

        </div>

        <div className={styles.menuContainer}>
          {menuItems.map((item) => (
            <div>
              <div className={styles.menuItem} onClick={(ev) => {
                ev.preventDefault();
                item.label === "テスト" ? toggleAlertModal() : handleClose(item.link);
              }}>
                <p>{item.label} <span className={styles.minf}>{item.minf}</span></p>
                <Menuicon className={styles.menuIcon} />
              </div>
              <div className={styles.menuDivider} />
            </div>
          ))}
          <div className={styles.menuItem} onClick={toggleLogoutModal}>
            <div>ログアウト</div>
            <Menuicon className={styles.menuIcon} />
          </div>
          <div className={styles.menuDivider} />
        </div>
        {isLogoutModal && <LogoutModal toggleLogoutModal={toggleLogoutModal} />}
        {isAlertModal && <AlertModal toggleAlertModal={toggleAlertModal} />}

      </div >
    </section >
  )
}
