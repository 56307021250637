import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { updateUserAct } from '../../store/actions/userActions'

export function AlertModal({ toggleAlertModal }) {
  const dispatch = useDispatch();
  const history = useHistory();


  useEffect(() => {
  }, [dispatch]);

  return (
    <section className="tearms">
      <div className="bg" onClick={toggleAlertModal}></div>
      <div className="container-paving">
        <div className="form-paving">
          <div className="div-7">
            <div className="div-8">
              <p> この機能は現在開発中です</p>
              <p> リリースをお待ちください</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
